import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next: HttpHandlerFn) => {
  const jwtToken = req.url.includes('client-refresh-token') ? getJwtToken('refreshToken') : getJwtToken('accessToken');
  if (jwtToken) {
    const cloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`
      }
    });

    return next(cloned);
  }

  return next(req);
}

function getJwtToken(type: string): string | null {
  let tokens: any = localStorage.getItem('JWT_TOKEN');
  if (!tokens) return null;
  return JSON.parse(tokens)[type];
}
