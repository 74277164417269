import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, ToastModule],
  providers: [MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private authService: AuthService = inject(AuthService);
  private storageService: StorageService = inject(StorageService);
  private http = inject(HttpClient);
  private messageService: MessageService = inject(MessageService);
  public isLoginPage = true;
  constructor(
    private router: Router,
    private primengConfig: PrimeNGConfig,
  ) {
    this.primengConfig.ripple = true;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === '/login';
      }
    });
  }

  ngOnInit() {
    // TODO: duplicate
    const tokens = localStorage.getItem('JWT_TOKEN');
    if (tokens) {
      const token: any = JSON.parse(tokens).accessToken;
      const decoded: any = jwtDecode(token);
      this.storageService.isAdmin = decoded.admin;
      // console.log('ngOnInit decoded', decoded);
    }

    // this.storageService.$changePlatform.subscribe((platform) => {
    //   if (platform) {
    //     this.storageService.getServicesAndLocations();
    //   }
    // });

    this.storageService.$message.subscribe((message: any) => {
      if (message) {
        this.messageService.add({ severity: message.severity, summary: message.summary, detail: message.detail });
      }
    })
    if (
      !this.storageService.$authEvent.value &&
      this.authService.isLoggedIn()
    ) {
      const requestUrl = this.storageService.isAdmin ? '/staffs/me' : '/clients/me';
      this.http
        .get(environment.API_PATH + requestUrl, {
          headers: {
            'x-user-id': localStorage.getItem('user_id') || ''
          }
        })
        .subscribe((res: any) => {
          if (res.platforms) {
            this.storageService.$platforms.next(res.platforms);
          }
          if (res.projects) {
            this.storageService.$projects.next(res.projects);
          }
          this.storageService.$userData.next({
            _id: res._id || res.id,
            name: res.name || res.full_name,
            email: res.email,
            quickbook_id: res.quickbook_id,
          });
          if (this.storageService.isAdmin) {
            this.storageService.getAllPlatforms();
          }
          this.storageService.getCurrentPlatformData();
          // this.storageService.getKpiData();
        });
    }
    this.storageService.$authEvent.subscribe((res) => {
      if (res && this.authService.isLoggedIn()) {
        const requestUrl = this.storageService.isAdmin ? '/staffs/me' : '/clients/me';
        this.http
          .get(environment.API_PATH + requestUrl, {
            headers: {
              'x-user-id': localStorage.getItem('user_id') || ''
            }
          })
          .subscribe((res: any) => {
            if (res.platforms) {
              this.storageService.$platforms.next(res.platforms);
            }
            if (res.projects) {
              this.storageService.$projects.next(res.projects);
            }
            this.storageService.$userData.next({
              _id: res._id || res.id,
              name: res.name || res.full_name,
              email: res.email,
              quickbook_id: res.quickbook_id,
            });
            if (this.storageService.isAdmin) {
              this.storageService.getAllPlatforms();
            }
            this.storageService.getCurrentPlatformData();
          });
      }
    });
  }
}
