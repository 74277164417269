import { Component, OnInit, inject } from '@angular/core';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { SkeletonModule } from 'primeng/skeleton';
import { RippleModule } from 'primeng/ripple';
import { DropdownFilterOptions, DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    RouterLinkActive,
    RouterLink,
    MenuModule,
    ConfirmDialogModule,
    SkeletonModule,
    RippleModule,
    DatePipe,
    DropdownModule,
    FormsModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  private authService: AuthService = inject(AuthService);
  public storageService: StorageService = inject(StorageService);
  private confirmationService: ConfirmationService = inject(ConfirmationService);
  public currentApplicationVersion = environment.app_version;
  public showGlfLogo: boolean = false;
  public readonly menuItems: Array<{ title: string; path: string }> = [
    {
      title: 'Dashboard',
      path: 'dashboard',
    },
    {
      title: 'Lead Center',
      path: 'lead-center',
    },
    {
      title: 'KPIs',
      path: 'kpi',
    },
    {
      title: 'Business Variables',
      path: 'settings',
    },
  ];
  public dropDownItems: Array<{
    value: string;
    label: string;
    automatic_lead_qualification_start: string;
  }> = [];

  public userName: string = '';
  public userMenuItems: any = [];
  public isAdminClass: boolean = false;
  public activeProject: any = {};

  constructor() {
    if (window.location.hostname.includes('growlawfirm')) {
      this.showGlfLogo = true;
    }
    this.isAdminClass = this.storageService.isAdmin;

    this.storageService.$activePlatform.subscribe(async (platform: string) => {
      if (platform?.length > 5) {
        this.activeProject = platform;
      }
    });

    this.storageService.$platforms.subscribe((platforms) => {
      if (platforms) {
        let platformDropdownList = [];
        for (const platform of platforms) {
          let abbr = platform?.project?.abbr_l;
          if (platform._id === '6720e35dfd724b6e228b1486') {
            abbr = 'SMP';
          }
          platformDropdownList.push({
            value: platform._id,
            label: platform.url,
            abbr_l: abbr,
            automatic_lead_qualification_start:
            platform.automatic_lead_qualification_start,
          });
        }

        this.sortByDate(platformDropdownList);
        this.dropDownItems = platformDropdownList;

        this.dropDownItems.forEach((el: any) => {
          el.label = el.label + '*' + el.abbr_l
        });
      }
    });

    setInterval(() => {
      this.authService.refreshToken()?.subscribe(() => {});
    }, 60000 * 1000 * 2,);
  }


  async ngOnInit() {
    await this.makeUserMenu();
  }

  async onDropdownChange(event: any) {
    console.log('onDropdownChange');
    sessionStorage.clear();
    localStorage.setItem('platform', event.value);
    this.storageService.kpiCache = null;
    this.storageService.$kpiData.next(null);
    this.storageService.$activePlatform.next(event.value);

    // await this.storageService.getCurrentPlatformData();

    this.storageService.gridApi?.setGridOption('loading', true);
    this.storageService.gridApi?.refreshServerSide();
  }

  private async makeUserMenu() {
    this.storageService.$userData.subscribe((userData: any) => {
      if (userData && userData.name) {
        const nameArray = userData.name
          ?.split(' ')
          ?.map((item: string) => item.toUpperCase()[0]);
        this.userName = nameArray?.slice(0, 2).join('');
        this.userMenuItems = [
          {
            label: userData.name,
            items: [
              {
                label: 'Logout',
                icon: 'pi pi-refresh',
                command: () => {
                  this.logout();
                },
              },
            ],
          },
        ];
      } else if (userData && userData.full_name) {
        this.userMenuItems = [
          {
            label: userData.full_name,
            items: [
              {
                label: 'Logout',
                icon: 'pi pi-refresh',
                command: () => {
                  this.logout();
                },
              },
            ],
          },
        ];
      }
    });
  }
  private sortByDate(arr: any) {
    arr.sort((a: any, b: any) => {
      if (
        !a.automatic_lead_qualification_start &&
        b.automatic_lead_qualification_start
      ) {
        return 1;
      }
      if (
        a.automatic_lead_qualification_start &&
        !b.automatic_lead_qualification_start
      ) {
        return -1;
      }
      if (
        !a.automatic_lead_qualification_start &&
        !b.automatic_lead_qualification_start
      ) {
        return 0;
      }
      return (
        +new Date(a.automatic_lead_qualification_start) -
        +new Date(b.automatic_lead_qualification_start)
      );
    });

    return arr;
  }

  public logout() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to log out?',
      header: 'Confirmation',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.authService.logout();
      },
    });
  }
}
