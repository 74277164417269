import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ToastModule } from 'primeng/toast';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, ToastModule],
  providers: [MessageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private authService: AuthService = inject(AuthService);
  private storageService: StorageService = inject(StorageService);
  private router: Router = inject(Router);
  private http = inject(HttpClient);
  private messageService: MessageService = inject(MessageService);
  public isLoginPage = true;

  constructor(
    private primengConfig: PrimeNGConfig,
  ) {
    this.primengConfig.ripple = true;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === '/login';
      }
    });
  }

  ngOnInit() {
    // TODO: duplicate
    const tokens = localStorage.getItem('JWT_TOKEN');
    if (tokens) {
      const token: any = JSON.parse(tokens).accessToken;
      const decoded: any = jwtDecode(token);
      this.storageService.isAdmin = decoded.admin;
    }

    this.storageService.$message.subscribe((message: any) => {
      if (message) {
        this.messageService.add({ severity: message.severity, summary: message.summary, detail: message.detail });
      }
    });

    if (!this.storageService.$authEvent.value && this.authService.isLoggedIn()) {
      const requestUrl = this.storageService.isAdmin ? '/staffs/me' : '/clients/me';
      this.http.get(environment.API_PATH + requestUrl, {
        headers: {
          'x-user-id': localStorage.getItem('user_id') || ''
        }
      }).subscribe(async (res: any) => this.initialAction(res));
    }

    this.storageService.$authEvent.subscribe((res) => {
      if (res && this.authService.isLoggedIn()) {
        const requestUrl = this.storageService.isAdmin ? '/staffs/me' : '/clients/me';
        this.http.get(environment.API_PATH + requestUrl, {
          headers: {
            'x-user-id': localStorage.getItem('user_id') || ''
          }
        }).subscribe(async (res: any) => this.initialAction(res));
      }
    });
  }


  private async initialAction(value: any) {
    if (value?.id || value?._id) {
      this.storageService.$userData.next({
        _id: value._id || value.id,
        name: value.name || value.full_name,
        email: value.email,
        quickbook_id: value.quickbook_id,
      });

      // if client login
      console.log('!this.storageService.isAdmin', !this.storageService.isAdmin, value);
      if (!this.storageService.isAdmin) {
        if (value.platforms) {
          this.storageService.$platforms.next(value.platforms);
        }
        if (value.projects) {
          this.storageService.$projects.next(value.projects);
        }
      } else {
        await this.storageService.getAllPlatforms();
      }

      if (localStorage.getItem('platform')) {
        this.storageService.$activePlatform.next(localStorage.getItem('platform'));
      } else {
        const allPlatforms = this.storageService.$platforms.getValue();
        this.storageService.$activePlatform.next(allPlatforms[0]._id);
        localStorage.setItem('platform', allPlatforms[0]._id);
      }
    }
  }
}
