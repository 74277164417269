import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

// https://github.com/ganatan/angular-react-routing/blob/master/angular/src/app/app.routes.ts
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((x) => x.LoginComponent)
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./pages/dashboard/dashboard.component').then((x) => x.DashboardComponent),
    canActivate: [authGuard],
  },
  {
    path: 'lead-center',
    loadComponent: () => import('./pages/lead-center/lead-center.component').then((x) => x.LeadCenterComponent),
    canActivate: [authGuard],
    children: [
      {
        path: ':recordId',
        loadComponent: () => import('./pages/lead-details/lead-details.component').then((x) => x.LeadDetailsComponent)
      }
    ]
  },
  {
    path: 'lead-center/:recordId',
    canActivate: [authGuard],
    loadComponent: () => import('./pages/lead-details/lead-details.component').then((x) => x.LeadDetailsComponent)
  },
  {
    path: 'kpi',
    canActivate: [authGuard],
    loadComponent: () => import('./pages/kpi/kpi.component').then((x) => x.KpiComponent)
  },
  {
    path: 'reports',
    canActivate: [authGuard],
    loadComponent: () => import('./pages/reports/reports.component').then((x) => x.ReportsComponent)
  },
  {
    path: 'settings',
    canActivate: [authGuard],
    loadComponent: () => import('./pages/settings/settings.component').then((x) => x.SettingsComponent)
  },
  {
    path: '**',
    loadComponent: () => import('./pages/not-found/not-found.component').then((x) => x.NotFoundComponent)
  }
];
