<p-confirmDialog></p-confirmDialog>
<header [class.admin-user]="isAdminClass" #header>
  <div class="appVersion">{{currentApplicationVersion}}</div>
  <div class="header__logo">
    <a href="/">
      @if (showGlfLogo) {
        <img ngSrc="/assets/glf-logo.svg" alt="glf-logo" height="32" width="32" priority>
      } @else {
        <img ngSrc="/assets/cwa-logo.svg" alt="cwa-logo" height="35" width="35" priority>
      }
    </a>
  </div>
  @if (dropDownItems.length) {
    <p-dropdown
      [options]="dropDownItems"
      [(ngModel)]="activeProject"
      (onChange)="onDropdownChange($event)"
      [filter]="true"
      [showClear]="false">
      <ng-template pTemplate="selectedItem" let-selectedOption>
        <div>{{ selectedOption.label.split('*')[0] }}</div>
      </ng-template>
      <ng-template let-val pTemplate="item" style="display: none">
        <div class="flex align-items-center gap-2">
          <div>{{val.label.split('*')[0]}} @if (val.abbr_l && val.abbr_l.length) { <b>({{val.abbr_l}})</b> }</div>
          <div class="drop-down-admin-platform__date">{{val.automatic_lead_qualification_start | date: 'MMM, y'}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  } @else {
    <p-skeleton width="263px" height="50px" [style]="{'margin-right': '16px'}"></p-skeleton>
  }

  <nav class="header__menu">
    @for (item of menuItems; track item.path) {
      <div class="header__menu-item"
           pRipple
           routerLink="{{item.path}}"
           routerLinkActive="header__menu-item--active">
        {{item.title}}
      </div>
    }
  </nav>

  <div #menuContainer (click)="logout()" class="user-profile-menu">
    {{userName}}
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5 7.5L10 12.5L15 7.5" stroke="#7986FF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>

</header>
