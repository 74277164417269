import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, retry, switchMap, take, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const authInterceptor: HttpInterceptorFn = (req, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const jwtToken = req.url.includes('client-refresh-token') ? getJwtToken('refreshToken') : getJwtToken('accessToken');
  if (jwtToken) {
    const cloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtToken}`
      }
    });

    return next(cloned);

    // return next(cloned).pipe(
    //   tap({
    //     error: (err) => {
    //       if (err.status === 401) {
    //         authService?.refreshToken()?.pipe(
    //           switchMap((tokenReceived) => {
    //             console.log('❌❌❌----', tokenReceived);
    //             let token: any = getJwtToken('accessToken');
    //             if (token != '') {
    //               req = req.clone({
    //                 setHeaders: {
    //                   Authorization: `Bearer ${token}`,
    //                 },
    //               })
    //             }
    //             return next(req);
    //           }
    //         ));
    //       }
    //     }
    //   })
    // )
    // return next(cloned).pipe(
      // retry(0),
      // @ts-ignore
      // catchError((error: HttpErrorResponse) => {
      //   if (error.status === 401) {
      //     // authService.refreshToken()?.subscribe(() => {})
      //     return authService?.refreshToken()?.pipe(
      //       switchMap((tokenReceived) => {
      //         console.log('❌❌❌----', tokenReceived);
      //         let token: any = getJwtToken('accessToken');
      //         if (token != '') {
      //           req = req.clone({
      //             setHeaders: {
      //               Authorization: `Bearer ${token}`,
      //             },
      //           })
      //         }
      //         return next(req);
      //       }
      //     ), catchError(err => {
      //         return throwError(() => new Error('Unauthorized Exception'));
      //       }));
      //   }
      //
      //   return throwError(() => new Error('An error occurred. Please try again later.'));
      // })
    // )
    // return next(cloned).pipe(
    //   tap({
    //     error: (err) => {
    //       if (err instanceof HttpErrorResponse && err.status === 401) {
    //         console.log('401');
    //         return authService?.refreshToken()?.pipe(
    //           switchMap((tokenReceived) => {
    //               console.log('❌❌❌----', tokenReceived);
    //               let token: any = getJwtToken('accessToken');
    //               if (token != '') {
    //                 req = req.clone({
    //                   setHeaders: {
    //                     Authorization: `Bearer ${token}`,
    //                   },
    //                 })
    //               }
    //               return next(req);
    //             }
    //           ));
    //       } else {
    //         console.log('❌----', err);
    //         return next(req);
    //       }
    //       // authService.refreshToken()?.subscribe(() => {});
    //     }
    //   }),
    // )
    // return next(cloned).pipe(
    //   switchMap((tokenReceived) => {
    //       console.log(tokenReceived);
    //       let token: any = getJwtToken('accessToken');
    //       if (token != '') {
    //         req = req.clone({
    //           setHeaders: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         })
    //       }
    //       return next(req);
    //     }
    //   ));
    // return next(cloned).pipe(
    //   tap({
    //     next: () => console.log('Request sent'),
    //     error: () => console.error('Request failed'),
    //     complete: () => console.log('Request completed'),
    //   })
    // );
  }
  return next(req);
}

function getJwtToken(type: string): string | null {
  let tokens: any = localStorage.getItem('JWT_TOKEN');
  if (!tokens) return null;
  return JSON.parse(tokens)[type];
}
